import {getSignature} from "r/index/index";
import {picUpload} from "r/index/envir";
import {fileUpload} from "r/index/upload";
import {Toast, Dialog, ImagePreview} from "vant";
import UploadSample from "c/index/UploadSample";
import hintImg0 from "a/images/index/hjimg/hjimg-1.png";
import hintImg1 from "a/images/index/hjimg/hjimg-2.png";
import hintImg2 from "a/images/index/hjimg/hjimg-3.png";
import hintImg3 from "a/images/index/hjimg/hjimg-4.png";
import hintImg4 from "a/images/index/hjimg/hjimg-5.png";
import hintImg5 from "a/images/index/hjimg/hjimg-6.png";
import hintImg6 from "a/images/index/hjimg/hjimg-7.jpg";
import hintImg7 from "a/images/index/upload/img-3.png";
import {studentStatus} from "r/index/studentStatus";

export const envirMixin = {
    components: {
        UploadSample
    },
    data() {
        return {
            isDisabled: false, // true-已经提交，禁用 false-可以提交
            isChecked: false, // 防重复点击
            newCount: 0, // 当前已上传的照片数
            fileList: {},
            hintImg: {
                0: hintImg0,
                1: hintImg1,
                2: hintImg2,
                3: hintImg3,
                4: hintImg4,
                5: hintImg5,
                6: hintImg6,
                7: hintImg7
            },
            dialogShowFu: false,// 控制上传示例弹框显示与隐藏
            formDataFu: {},
            submit:true
        }
    },
    created() {

        localStorage.setItem("studentStatusTime",0);
        let exam_uuid = localStorage.getItem("monitor-subjectUuId");
        let sfzNum = localStorage.getItem("monitor-zkz");
        this.studentStatusPost(sfzNum,exam_uuid);
        // 回显
        let monitorEnvir = window.localStorage.getItem('monitor-envir-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"));
        if (monitorEnvir) {
            this.fileList = JSON.parse(monitorEnvir);
            // for (let k in this.fileList) {
            //     if (this.fileList[k] && this.fileList[k].length > 0) {
            //         this.newCount++;
            //     }
            // }
        }
    },
    methods: {
        studentStatusPost(sfzNum,exam_uuid){
            let time1 = Date.parse(new Date());
            let studentStatusTime = localStorage.getItem("studentStatusTime");
            if( time1 > parseInt(studentStatusTime) + 10 || studentStatusTime == 0){
                studentStatus(sfzNum,exam_uuid).then( (res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    localStorage.setItem("studentStatusTime", time1);
                    if(res.data.renzheng == 0 ){ //认证 == 0
                        this.$router.replace('/camera');
                    }
                    if(res.data.huanjing == 1){ //环境 == 1
                        this.$router.replace('/position');
                    }
                });
            }
        },

        // 图片预览
        viewImage(arr) {
            ImagePreview(arr)
        },
        // 打开上传弹框
        openDialog(idx) {
            this.dialogShowFu = false;
           let aaa = setTimeout(()=>{
               aaa && clearTimeout(aaa);
                this.formDataFu = {
                    idx: idx,
                    hintImg: this.hintImg[idx]
                }
                this.dialogShowFu = true;
            }, 500)
        },
        uploadSuccess(url,idx){
            this.fileList[idx] = url;
            this.$forceUpdate();
            console.log(this.dialogShowFu)
            window.localStorage.setItem('monitor-envir-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"), JSON.stringify(this.fileList));
        },
        // 子组件触发，关闭上传示例弹框
        closeDialog() {
            this.dialogShowFu = false;
        },
        // 取消
        onCancel() {
            this.$router.replace('/position');//openBack 提示打开摄像头修改为直接调到 正面监控/背面监控页面中/position add 20220508 baiansen
        },
        // 提交
        onCommit() {
            if(this.submit){
                this.submit = false;
                localStorage.setItem("studentStatusTime",0);

                let exam_uuid = localStorage.getItem("monitor-subjectUuId");
                let sfzNum = localStorage.getItem("monitor-zkz");

                studentStatus(sfzNum,exam_uuid).then( (res) => {
                    localStorage.setItem("monitor-huanjing", res.data.huanjing);
                    localStorage.setItem("monitor-renzheng", res.data.renzheng);
                    localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                    if(res.data.huanjing == 1){
                        this.$router.replace('/promise');
                    }else{
                        if (this.isDisabled) {
                            this.submit = true;
                            return Toast('不能重复提交');
                        }
                        let newCount = 0;
                        for (let k in this.fileList) {
                            if (this.fileList[k] && this.fileList[k].length > 0) {
                                newCount++;
                            }
                        }
                        if (newCount < 1) { // if (newCount < 8) { 1、上传环境照片 3、上传正式考试试卷袋照片删除  清华附中-民乐 20220508 baiansen
                            this.submit = true;
                            return Toast('照片未上传完成');
                        }
                        Dialog.confirm({
                            title: '提示',
                            message: '确认提交吗？',
                        })
                            .then(() => {
                                const zkz = localStorage.getItem('monitor-zkz');
                                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                                let arr = [];
                                console.log(this.fileList)
                                for (let k in this.fileList) {
                                    if (this.fileList[k] && this.fileList[k].length > 0) {
                                        arr.push(this.fileList[k]);
                                    }
                                }
                                let obj = {
                                    images: arr.join(','),
                                }
                                // on confirm
                                picUpload(subjectUuid, zkz, obj)
                                    .then(res => {
                                        console.log(res)
                                        if (res.data.code == 200) {
                                            Toast(res.data.result);
                                            this.$router.replace('/promise')
                                        } else if (res.data.code == 402) {
                                            Toast(res.data.result);
                                            this.$router.replace('/promise')
                                        } else {
                                            Toast(res.data.result);
                                        }
                                    })
                                    .catch(err => {
                                        this.submit = true;
                                        Toast('提交失败');
                                        console.log(err)
                                    })
                            })
                            .catch(() => {
                                this.submit = true;
                                // on cancel
                                Toast('取消提交');
                            });
                    }
                });
            }else{
                Notify({ type: 'warning', message: '请稍后' ,color: '#eee',
                background: '#8b2d87'});
            }


        },
        // 删除
        beforeDeleteAnswer(idx) {
            this.fileList[idx] = '';
            // this.newCount--;
            this.$forceUpdate();
            window.localStorage.setItem('monitor-envir-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem("monitor-subjectUuId"), JSON.stringify(this.fileList));
        },
        // 上传作品-读取文件
        beforeReadAnswer(file) {
            return file;
        },
        // 上传作品
        afterReadAnswer(file, idx) {
            this.uploadHandle(file, idx);
        },
        async uploadHandle(file, idx) {

            let exam_uuid = localStorage.getItem("monitor-subjectUuId");
            let sfzNum = localStorage.getItem("monitor-zkz");
            studentStatus(sfzNum,exam_uuid).then( (res) => {
                localStorage.setItem("monitor-huanjing", res.data.huanjing);
                localStorage.setItem("monitor-renzheng", res.data.renzheng);
                localStorage.setItem("monitor-chengnuoshu", res.data.chengnuoshu);
                localStorage.setItem("studentStatusTime", time1);
                if(res.data.huanjing == 1){
                    this.$router.replace('/promise');
                }else{
                    file.status = 'uploading';
                    file.message = '上传中...';
                    const zkz = localStorage.getItem('monitor-zkz');
                    const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                    const fileName = 'envir/' + zkz + '_' + subjectUuid + '_' + idx + '.jpg';
                    getSignature().then( (data) => {
                        fileUpload(data.data, file.file, fileName).then( (uploadData) => {
                            if (uploadData.data.Status === 'OK') {
                                file.status = 'success';
                                file.message = '上传成功';
                                file.fileName = fileName
                                this.fileList[idx] = [{
                                    url: uploadData.data.url,
                                    status: 'success'
                                }];
                                // this.newCount++;
                                window.localStorage.setItem('monitor-envir-' + zkz + '-' + subjectUuid, JSON.stringify(this.fileList));
                                Toast.success('上传成功');
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                                this.fileList[idx] = [];
                                Toast.fail('上传失败');
                            }
                        });
                    });
                }
            });
        },
    }
};
