<template>
    <van-dialog
            v-model="dialogShow"
            width="100%"
            class="upload-sample"
            title=""
            :showConfirmButton="false"
            :showCancelButton="false"
    >
        <!--正面-->
        <div class="hint-txt" v-if="formData.idx==0">参照示例拍照并上传。照片中须呈现正面墙面和画板，如使用桌子须包含桌面。</div>
        <!--左、后、右、上-->
        <div class="hint-txt" v-if="formData.idx==1||formData.idx==2||formData.idx==3||formData.idx==4">参照示例拍照并上传。</div>
        <!--下方-->
        <div class="hint-txt" v-if="formData.idx==5">参照示例拍照并上传。照片中须呈现考试座位附近地面环境，包括椅子四脚和桌底。</div>
        <!--手持证件照-->
        <div class="hint-txt" v-if="formData.idx==6">照片须包含清晰的人脸、身份证（头像、证件号）。</div>
        <!--试卷袋照-->
        <div class="hint-txt" v-if="formData.idx==7">照片须清晰呈现完整试卷袋及封条。</div>

        <div class="hint-img">
            <van-image :src="formData.hintImg"></van-image>
            <div class="img-txt">示例图片</div>
        </div>
        <div class="hint-btn">
            <van-uploader
                    ref="uploadImg"
                    v-model="fileList"
                    max-count="1"
                    capture="camera"
                    accept="image/*"
                    :preview-image="false"
                    :preview-full-image="false"
                    :before-read="beforeReadAnswer"
                    :after-read="afterReadAnswer"
                    style="display:none;"
            >
            </van-uploader>
            <van-button type="primary" :loading="!!loadingArr[formData.idx]" loading-text="上传中..." class="confirm"
                        @click="onConfirm">
                拍照上传
            </van-button>
            <van-button type="text" class="cancel" @click="onCancel">取消</van-button>
        </div>
    </van-dialog>
</template>

<script>
    import {Toast} from "vant";
    import {getSignature} from "r/index/index";
    import {fileUpload} from "r/index/upload";
    import {nanoid} from "nanoid";


    export default {
        name: "UploadSample",
        props: {
            dialogShowFu: [Boolean],
            formDataFu: [Object, String]
        },
        data() {
            return {
                fileList: [],
                dialogShow: this.dialogShowFu,
                formData: this.formDataFu,
                loadingArr: {}
            }
        },
        methods: {
            // 拍照上传
            onConfirm() {
                console.log(this.$refs['uploadImg'])
                this.$refs['uploadImg'].chooseFile();
            },
            // 取消
            onCancel() {
                this.fileList = [];
                this.formData = {};
                this.dialogShow = false;
                this.$emit('closeDialog');
            },
            // 上传作品-读取文件
            beforeReadAnswer(file) {
                this.loading = true;
                return file;
            },
            // 上传作品
            afterReadAnswer(file) {
                this.uploadHandle(file, this.formData.idx);
            },
            async uploadHandle(file, idx) {
                file.status = 'uploading';
                file.message = '上传中...';
                this.loadingArr[idx] = 1;
                const zkz = localStorage.getItem('monitor-zkz');
                const subjectUuid = localStorage.getItem("monitor-subjectUuId");
                const fileName = 'envir/' + zkz + '_' + subjectUuid + '_' + nanoid() + '_' + idx + '.jpg';

                let signatureOss = window.localStorage.getItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'));
                if (signatureOss && signatureOss !== '{}' && Object.keys(JSON.parse(signatureOss)).length > 0) {
                    const data = JSON.parse(signatureOss);
                    await this.uploadRequest(data, file, fileName, idx);
                } else {
                    const {data} = await getSignature();
                    window.localStorage.setItem('monitor-signature-' + localStorage.getItem('monitor-zkz') + '-' + localStorage.getItem('monitor-subjectUuId'), JSON.stringify(data));
                    await this.uploadRequest(data, file, fileName, idx)
                }
                this.loadingArr[idx] = 0;
                // const {data} = await getSignature();
                // const uploadData = await fileUpload(data, file.file, fileName);
                // if (uploadData.data.Status === 'OK') {
                //     this.loading = false;
                //     file.status = 'success';
                //     file.message = '上传成功';
                //     file.fileName = fileName
                //     this.fileList = [{
                //         url: uploadData.data.url,
                //         status: 'success'
                //     }];
                //     Toast.success('上传成功');
                //     this.fileList = [];
                //     this.dialogShow = false;
                //     this.$emit('closeDialog', this.dialogShow, uploadData.data.url, idx);
                // } else {
                //     this.loading = false;
                //     file.status = 'failed';
                //     file.message = '上传失败';
                //     this.fileList = [];
                //     Toast.fail('上传失败');
                // }
            },

            // 上传请求
            async uploadRequest(data, file, fileName, idx) {
                const uploadData = await fileUpload(data, file.file, fileName);
                if (uploadData.data.Status === 'OK') {
                    this.loading = false;
                    file.status = 'success';
                    file.message = '上传成功';
                    file.fileName = fileName
                    this.fileList = [{
                        url: uploadData.data.url,
                        status: 'success'
                    }];
                    Toast.success('上传成功');
                    this.fileList = [];
                    this.dialogShow = false;
                    this.$emit('uploadSuccess', uploadData.data.url, idx);
                } else {
                    this.loading = false;
                    file.status = 'failed';
                    file.message = '上传失败';
                    this.fileList = [];
                    Toast.fail('上传失败');
                }
            },
        },
        watch: {
            dialogShowFu() {
                this.dialogShow = this.dialogShowFu;
            },
            formDataFu() {
                this.formData = this.formDataFu;
            }
        }
    }
</script>

<style scoped lang="scss">
    .upload-sample {
        width: 90% !important;
        max-width: 400px;
        max-height: 85%;
        display: flex;
        flex-flow: column;

        ::v-deep .van-dialog__content {
            flex: 1;
            padding: 25px 20px 128px;
            overflow-y: scroll;
            text-align: center;
            font-family: "PingFang SC";

            .hint-txt {
                text-align: left;
                font-size: 14px;
                line-height: 20px;
                margin: 0 0 10px 0;
            }

            .hint-img {
                width: 100%;

                .van-image {
                    width: 60%;
                    min-width: 200px;
                    min-height: 140px;
                    border-radius: 8px;

                    .van-image__img {
                        border-radius: 8px;
                    }
                }

                .img-txt {
                    text-align: center;
                    font-size: 14px;
                    line-height: 20px;
                    margin: 5px 0 10px;
                }
            }

            .hint-btn {
                /*margin-top: 20px;*/
                background: rgba(255, 255, 255, 0.9);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                padding: 0 25px 20px;

                .confirm {
                    width: 100%;
                    border-radius: 8px;
                    font-size: 14px;
                    color: #ffffff;
                    border-color: #563279;
                    background-color: #563279;

                    .van-uploader__input-wrapper {
                        width: 100%;
                    }
                }

                .cancel {
                    border-radius: 8px;
                    font-size: 14px;
                    color: #1989fa;
                    text-decoration: underline;
                    border-color: transparent;
                    background-color: transparent;

                    &::before {
                        background-color: transparent !important;
                    }
                }
            }
        }
    }
</style>

