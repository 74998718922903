<template>
    <div class="upload-container">
        <div class="upload_header">
            <van-nav-bar
                    title=""
                    left-text="取消"
                    right-text="提交"
                    @click-left="onCancel"
                    @click-right="onCommit"/>
        </div>
        <div class="upload_content">
            <!--<div class="subject-name">1、上传环境照片</div>
            <div class="subject-text">请以考试座位为中心，面向画板或考试桌面为正前方，分别向前方、左方、后方、右方、上方及下方环境拍照上传。</div>
            <div class="upload-area">
                <div class="upload-item">
                    <div v-if="fileList[0]&&fileList[0]!=''&&fileList[0]!=null" class="upload-item-yes">
                        <van-image :src="fileList[0]" fit="cover" @click="viewImage([fileList[0]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(0)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(0)">
                        <van-icon name="plus"/>
                        <div class="upload-text">前方</div>
                    </div>
                </div>
                <div class="upload-item">
                    <div v-if="fileList[1]&&fileList[1]!=''&&fileList[1]!=null" class="upload-item-yes">
                        <van-image :src="fileList[1]" fit="cover" @click="viewImage([fileList[1]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(1)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(1)">
                        <van-icon name="plus"/>
                        <div class="upload-text">左方</div>
                    </div>
                </div>
                <div class="upload-item">
                    <div v-if="fileList[2]&&fileList[2]!=''&&fileList[2]!=null" class="upload-item-yes">
                        <van-image :src="fileList[2]" fit="cover" @click="viewImage([fileList[2]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(2)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(2)">
                        <van-icon name="plus"/>
                        <div class="upload-text">后方</div>
                    </div>
                </div>
            </div>
            <div class="upload-area">
                <div class="upload-item">
                    <div v-if="fileList[3]&&fileList[3]!=''&&fileList[3]!=null" class="upload-item-yes">
                        <van-image :src="fileList[3]" fit="cover" @click="viewImage([fileList[3]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(3)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(3)">
                        <van-icon name="plus"/>
                        <div class="upload-text">右方</div>
                    </div>
                </div>
                <div class="upload-item">
                    <div v-if="fileList[4]&&fileList[4]!=''&&fileList[4]!=null" class="upload-item-yes">
                        <van-image :src="fileList[4]" fit="cover" @click="viewImage([fileList[4]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(4)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(4)">
                        <van-icon name="plus"/>
                        <div class="upload-text">上方</div>
                    </div>
                </div>
                <div class="upload-item">
                    <div v-if="fileList[5]&&fileList[5]!=''&&fileList[5]!=null" class="upload-item-yes">
                        <van-image :src="fileList[5]" fit="cover" @click="viewImage([fileList[5]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(5)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(5)">
                        <van-icon name="plus"/>
                        <div class="upload-text">下方</div>
                    </div>
                </div>
            </div>-->
            <div class="subject-name">上传手持证件照片</div>
            <div class="subject-text">照片须包含清晰的人脸、身份证（头像、证件号）及准考证（头像、准考证号）。</div>
            <div class="upload-area">
                <div class="upload-item">
                    <div v-if="fileList[6]&&fileList[6]!=''&&fileList[6]!=null" class="upload-item-yes">
                        <van-image :src="fileList[6]" fit="cover" @click="viewImage([fileList[6]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(6)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(6)">
                        <van-icon name="plus"/>
                    </div>
                </div>
            </div>

           <!-- 20220508 baiansen <div class="subject-name">3、上传正式考试试卷袋照片</div>
            <div class="subject-text">照片须清晰呈现完整试卷袋（封口面）及封条。</div>
            <div class="upload-area">
                <div class="upload-item">
                    <div v-if="fileList[7]&&fileList[7]!=''&&fileList[7]!=null" class="upload-item-yes">
                        <van-image :src="fileList[7]" fit="cover" @click="viewImage([fileList[7]])"></van-image>
                        <van-icon name="cross" @click="beforeDeleteAnswer(7)"/>
                    </div>
                    <div v-else class="upload-item-no" @click="openDialog(7)">
                        <van-icon name="plus"/>
                    </div>
                </div>
            </div>-->
        </div>

        <!--<div class="upload_content">
            <div class="subject-name">1、环境照片</div>
            <div class="subject-text">请以考试座位为中心，面向画板或考试桌面为正前方，分别向前方、左方、后方、右方、上方及下方环境拍照上传。</div>
            <div class="upload-area">
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[0]"
                        :preview-full-image="true"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="前方"
                        @click.native="openDialog($event, 0)"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 0)"
                        :before-delete="file=>beforeDeleteAnswer(file, 0)"
                >
                </van-uploader>
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[1]"
                        :preview-full-image="false"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="左方"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 1)"
                        :before-delete="file=>beforeDeleteAnswer(file, 1)"
                >
                </van-uploader>
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[2]"
                        :preview-full-image="false"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="后方"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 2)"
                        :before-delete="file=>beforeDeleteAnswer(file, 2)"
                >
                </van-uploader>
            </div>
            <div class="upload-area">
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[3]"
                        :preview-full-image="false"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="右方"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 3)"
                        :before-delete="file=>beforeDeleteAnswer(file, 3)"
                >
                </van-uploader>
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[4]"
                        :preview-full-image="false"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="上方"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 4)"
                        :before-delete="file=>beforeDeleteAnswer(file, 4)"
                >
                </van-uploader>
                <van-uploader
                        ref="uploadImg"
                        v-model="fileList[5]"
                        :preview-full-image="false"
                        max-count="1"
                        capture="camera"
                        accept="image/*"
                        upload-text="下方"
                        :before-read="beforeReadAnswer"
                        :after-read="file=>afterReadAnswer(file, 5)"
                        :before-delete="file=>beforeDeleteAnswer(file, 5)"
                >
                </van-uploader>
            </div>

            <div class="subject-name">2、上传手持证件照</div>
            <div class="subject-text">照片须包含清晰的人脸、身份证(头像、证件号)及准考证(头像、准考证号)</div>
            <van-uploader
                    ref="uploadImg"
                    v-model="fileList[6]"
                    :preview-full-image="false"
                    max-count="1"
                    capture="camera"
                    accept="image/*"
                    :before-read="beforeReadAnswer"
                    :after-read="file=>afterReadAnswer(file, 6)"
                    :before-delete="file=>beforeDeleteAnswer(file, 6)"
            >
            </van-uploader>

            <div class="subject-name">3、上传试卷袋照</div>
            <div class="subject-text">照片须清晰呈现完整试卷袋及封条。</div>
            <van-uploader
                    ref="uploadImg"
                    v-model="fileList[7]"
                    :preview-full-image="false"
                    max-count="1"
                    capture="camera"
                    accept="image/*"
                    :before-read="beforeReadAnswer"
                    :after-read="file=>afterReadAnswer(file, 6)"
                    :before-delete="file=>beforeDeleteAnswer(file, 6)"
            >
            </van-uploader>
        </div>-->

        <!--上传示例弹框-->
        <upload-sample
                :dialogShowFu="dialogShowFu"
                :formDataFu="formDataFu"
                @uploadSuccess="uploadSuccess"
                @closeDialog="closeDialog"
        ></upload-sample>
    </div>
</template>

<script>
    import {envirMixin} from "mixins/index/envir";
    import UploadSample from "../../../components/index/UploadSample";

    export default {
        name: "envirPhoto",
        components: {UploadSample},
        mixins: [envirMixin],
    }
</script>

<style scoped lang="scss">
    .upload-container {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-flow: column;

        .upload_header {
            position: fixed;
            width: 100%;
            background-color: #ffffff;
            z-index: 10;

            ::v-deep .van-nav-bar {
                .van-nav-bar__left {
                    .van-nav-bar__text {
                        color: #ee0a24;
                    }
                }

                .van-nav-bar__right {
                    .van-nav-bar__text {
                        color: #8b2d87;
                    }
                }

            }
        }

        .upload_content {
            flex: 1;
            width: 95%;
            margin: 48px auto 2%;
            border: 2px dashed #aaaaaa;
            padding: 0 10px 10px;
            background-color: #fff;
            font-family: "PingFang SC";

            .subject-name {
                margin: 15px 0 8px;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;
            }

            .subject-text {
                margin: 8px 0;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
            }

            .upload-area {
                display: flex;
                flex-flow: row;
                justify-content: space-between;

                .upload-item {
                    width: 80px;
                    height: 80px;
                    background-color: #f5f5f5;
                    /*border-radius: 8px;*/
                    margin-bottom: 8px;

                    .van-image {
                        width: 100%;
                        height: 100%;
                    }

                    .upload-item-yes {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        .van-icon-cross {
                            position: absolute;
                            right: 0;
                            top: 0;
                            transform: translate(50%, -50%);
                            width: 16px;
                            height: 16px;
                            line-height: 16px;
                            text-align: center;
                            border-radius: 50%;
                            background-color: #b2b2b2;
                            color: #fff;

                            &::before {
                                font-size: 16px;
                                -webkit-transform: scale(.5);
                                transform: scale(.5);
                            }
                        }
                    }

                    .upload-item-no {
                        position: relative;
                        width: 100%;
                        height: 100%;

                        .van-icon-plus {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: #E5E5E5;
                            font-size: 40px;
                        }

                        .upload-text {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            margin: 0;
                            padding: 0;
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            text-align: center;
                            color: #ffffff;
                            font-size: 12px;
                            background-color: rgba(0, 0, 0, 0.4);
                            /*border-bottom-left-radius: 8px;*/
                            /*border-bottom-right-radius: 8px;*/
                        }
                    }
                }
            }

            ::v-deep .van-uploader {
                vertical-align: middle;
                margin-bottom: 8px;

                .van-uploader__upload {
                    background-color: #f5f5f5;
                    /*border-radius: 8px;*/
                    margin: 0;

                    .van-uploader__upload-text {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        text-align: center;
                        color: #ffffff;
                        background-color: rgba(0, 0, 0, 0.4);
                        /*border-bottom-left-radius: 8px;*/
                        /*border-bottom-right-radius: 8px;*/
                    }

                    .van-uploader__upload-icon {
                        color: #E5E5E5;
                        font-size: 40px;
                    }
                }

                .van-uploader__preview {
                    position: relative;
                    background-color: #f5f5f5;
                    /*border-radius: 8px;*/

                    .van-uploader__preview-delete {
                        position: absolute;
                        right: 0;
                        top: 0;
                        transform: translate(50%, -50%);
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #b2b2b2;

                        .van-icon {
                            top: 0;
                            right: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
</style>

