import {request} from "./network";

// 提交环境照片
export function picUpload(subjectUuid, zkNum, data) {
    return request({
        method: "post",
        url: `alivc/picUpload/${subjectUuid}/${zkNum}`,
        data,
    });
}
